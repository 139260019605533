<template>
  <div class="typ-container">
    <h3>Grazie!</h3>
    <div></div>
    <p>
      Verrai ricontattato al più presto da un consulente TIM nelle seguenti
      fasce orarie:
    </p>
    <p>
      Il servizio è gratuito ed è attivo<br />
      lun-ven dalle 9 alle 21 e sab-dom dalle 9 alle 18
    </p>
    <Analytics />
    <iframe
      v-bind:src="'https://api.komparatore.it/api/AB4086ECD47C568D5BA5739D4078988F?p1=Lead-TIM&p2=Komparatore&p3=' + this.orderID"
      height="0"
      width="0"
      style="display: none; visibility: hidden"
    ></iframe>
  </div>
</template>

<script>
import Analytics from "../components/Analytics.vue";
export default {
  name: "Typ",
  data() {
    return {
      orderID: this.$route.query.orderID
    }
  },
  components: {
    Analytics,
  },
  created() {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({event: 'Lead', Event: 'Lead'});
    console.log("event",dataLayer);
  }
};
</script>
<style>
.typ-container {
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  width: 100%;
  height: 50%;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-align: center;
  align-items: center;
}

.typ-container h3 {
  font-weight: 400;
  font-size: 30px;
  margin: auto;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  color: #000;
  text-align: center;
  font-family: Tahoma;
}

.typ-container div {
  height: 2vh;
}

.typ-container p {
  font-weight: 400;
  font-family: Tahoma, sans-serif !important;
  font-size: 20px;
  color: #676767;
  text-align: center;
  margin-bottom: 20px !important;
}
</style>
