<template>
  <div class="form-container">
    <p id="titleForm">
      Inserisci i tuoi dati, sarai contattato entro 48 ore da un consulente di
      TIM che ti aiuterà <b>gratuitamente</b> e <b>senza impegno</b> a scegliere
      la tariffa migliore <b>per i nuovi clienti</b>.
    </p>
    <form id="form" @submit="checkForm">
      <div class="input-container">
        <div></div>
        <input
          type="text"
          class="input"
          v-on:keyup="reset()"
          v-bind:class="{ 'error-input': this.name.error }"
          placeholder="Nome e Cognome"
          v-model="name.property"
        />
      </div>
      <span
        class="input-message-error"
        v-bind:style="[
          this.name.error ? { display: 'block' } : { display: 'none' },
        ]"
        >{{ this.name.error }}</span
      >
      <div class="input-container">
        <div></div>
        <input
          type="text"
          class="input"
          v-on:keyup="reset()"
          v-bind:class="{ 'error-input': this.phone.error }"
          placeholder="Telefono"
          v-model="phone.property"
        />
      </div>
      <span
        class="input-message-error"
        v-bind:style="[
          this.phone.error ? { display: 'block' } : { display: 'none' },
        ]"
        >{{ this.phone.error }}</span
      >
      <a id="navLink" target="_blank" href="/privacy">
        Leggi l'informativa sulla privacy di TIM</a
      >
      <p id="privacyText">
        Inserendo il mio numero di telefono, autorizzo TIM a contattarmi per
        ricevere assistenza e informazioni sull'acquisto delle offerte TIM.
        Dichiaro di aver preso visione dell'<a href="/privacy" target="_blank">informativa privacy</a> di essere
        l'intestatario del numero fornito e di essere a conoscenza delle
        sanzioni previste dalla vigente normativa in caso di rilascio di
        dichiarazioni mendaci.
      </p>
      <div>
        <input type="submit" value="Contattami" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  el: "#form",
  data() {
    return {
      name: {
        property: null,
        error: null,
      },
      phone: {
        property: null,
        error: null,
      },
      hasError: false,
    };
  },
  methods: {
    reset: function () {
      this.name.error = false;
      this.phone.error = false;
    },
    checkForm: async function (e) {
      e.preventDefault();
      const apiUrl = "https://server-lead.makeyoudigit.com/leads/176";

      if (!this.name.property && !this.phone.property) {
        this.name.error = "Indicare il nome del richiedente";
        this.phone.error = "Indicare il telefono di contatto";
        return;
      }

      if (!this.name.property) {
        this.name.error = "Indicare il nome del richiedente";
        return;
      }

      if (!this.phone.property) {
        this.phone.error = "Indicare il telefono di contatto";
        return;
      }

      let data = {
        name: this.name.property,
        phone: this.phone.property,
      };

      let response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      response = await response.json();

      if (response.error) {
        return alert(response.error);
      }
      return this.$router.push(`/thanks?orderID=${response.orderID}`);
    },
  },
};
</script>

<style scoped>
#titleForm {
  margin: auto;
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: justify;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  color: #000;
  text-align: justify;
  color: #000;
  font-family: "Tahoma";
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin: 8px 0;
  font-size: 18px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  font-family: Karla;
  color: #fff;
}

.input-container div {
  font-weight: 400;
  margin: 8px 0;
  font-size: 18px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  font-family: Karla;
  color: #fff;
}

input[type="text"] {
  border-radius: 5px;
  border: none;
  text-align: center;
  background-color: #e4f1fd;
  padding: 15px 0 15px 2%;
  width: 98%;
  color: #000;
  outline: none;
  font-family: Tahoma;
  font-weight: 50;
  opacity: 1;
  font-size: 1em;
  border: 0;
  outline: 0;
}

input[type="submit"] {
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1f8c1f),
    color-stop(48%, #2aac2a),
    color-stop(49%, #019401),
    color-stop(82%, #019401),
    to(#018301)
  );
  background-image: -webkit-linear-gradient(
    top,
    #1f8c1f,
    #2aac2a 48%,
    #019401 49%,
    #019401 82%,
    #018301
  );
  background-image: -o-linear-gradient(
    top,
    #1f8c1f 0,
    #2aac2a 48%,
    #019401 49%,
    #019401 82%,
    #018301 100%
  );
  background-image: linear-gradient(
    180deg,
    #1f8c1f 0,
    #2aac2a 48%,
    #019401 49%,
    #019401 82%,
    #018301
  );
  border: 2px solid #019401;
  text-shadow: 0 1px rgba(0, 0, 0, 0.5);
}

input[type="submit"]:hover {
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  outline: none;
}

.error-input {
  border-radius: 5px !important;
  border: none !important;
  text-align: center !important;
  border: 2px solid red !important;
  background-color: #e4f1fd;
  padding: 15px 0 15px 2%;
  width: 98%;
  color: #000;
  outline: none;
}

.input-message-error {
  font-weight: 700;
  color: red;
  font-family: Tahoma, sans-serif !important;
}

#navLink {
  display: block;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  font-family: Tahoma;
  font-weight: 700;
  text-decoration: underline;
}

#privacyText {
  font-size: 13px;
  font-family: Tahoma, sans-serif !important;
  text-align: justify;
  color: #676767;
}

/*phone layout*/
@media screen and (max-width: 480px) {
    #titleForm {
        font-size: 15px;
        margin-bottom: 5px;
    }
    #navLink {
        margin-top: 5px;
        font-size: 13px;
    }
    .input-message-error {
        font-size: 13px
    }
}

</style>
