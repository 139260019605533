<template>
  <div id="home">
    <img alt="tim logo" id="logo" src="../assets/tim.png" />
    <Form />
    <Analytics />
  </div>
</template>

<script>
import Form from "../components/Form.vue";
import Analytics from "../components/Analytics.vue";
export default {
  name: "Home",
  components: {
    Form,
    Analytics
  },
};
</script>

<style>
#logo {
  width: 10em;
  margin: auto;
}

@media screen and (max-width: 480px) {
  #logo {
    width: 7em;
  }
}
</style>
