import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Home from './pages/Home.vue';
import Typ from './pages/Typ.vue';
import Privacy from './pages/Privacy.vue';

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {path: '/', component: Home },
    {path: '/thanks', component: Typ},
    {path: '/privacy', component: Privacy},
    {path: '*', redirect: '/'}
  ]
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
